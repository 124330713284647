import React from 'react'
import { useEffect } from 'react'
import './HomeStyle.css'

export default function HomePageLeftHalf(props) {
  useEffect(() => {
    // To display the video for scenario selected on controller. We'll determine the scenario at the time the page is loaded, and will show video only for that scenario, until the page is reloaded.    
    document.getElementsByClassName("MapVid")[0].innerHTML = `<video style="width: 88%" autoPlay muted id="MapVid"> <source src="/videos/Map${props.CurrentScenario}.mp4" type="video/mp4" autoPlay /> </video>`

    // To keep videos ongoing and in sync.
    let currentTime = new Date();
    let videoIsAt = (currentTime - props.siteStartedAt) / 1000
    document.getElementById("MapVid").currentTime = videoIsAt
  })

  return (
    <div>
      <div className='item1' style={{ background: 'rgb(246, 248, 250)', padding: 0, maxHeight: '100%', overflow: 'hidden', zoom: 1.3 }}>
        <div style={{ position: 'relative', minHeight: '80px' }}>
          <img className='MapVidHeader' src="/images/MapVidHeader.png" alt="Map" style={{ left: 0, position: 'absolute', width: '100%' }}/>
        </div>
        <div className='MapVid'>
          <video style={{ width: '92.6%' }} autoPlay={true} muted={true} id="MapVid" alt="Map Video">
            <source src="/videos/Map1.mp4" type="video/mp4" autoPlay={true} />
          </video>
        </div>
      </div>
    </div>
  )
}
