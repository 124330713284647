import React from 'react'
import './CameraViewStyle.css'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

export default function Content(props) {
    const [currentVid, setCurrentVid] = useState(0)

    // To keep videos ongoing and in sync.
    useEffect(() => {
        let currentTime = new Date();
        let videoIsAt = ((currentTime - props.siteStartedAt) / 1000)
        document.getElementById("Vid").currentTime = videoIsAt

        // Handling the video ends event
        document.getElementById('Vid').addEventListener('ended', function (e) {
            try{document.getElementsByTagName("video")[0].replaceWith("You've safely reached the destination")}
            catch(e){console.log(e)}
        })
    }, [currentVid])

    useEffect(() => {
        document.getElementsByClassName("FrontImgVid")[0].innerHTML = `<video autoPlay muted id="Vid" style="max-width:100%">
        <source src="/videos/Trip${props.CurrentScenario}FrontCamera.mkv" type="video/mp4" autoPlay />
        </video>`
        setCurrentVid(currentVid + 1)
    }, [props.CurrentScenario])

    // Becomes functional as buttons are pressed to choose between the different camera views.
    function initializePage(e) {
        if (e.currentTarget.id) {
            if (e.currentTarget.id === "Front") {
                // To display the video for scenario selected on controller.
                document.getElementsByClassName("FrontImgVid")[0].innerHTML = `<video autoPlay muted id="Vid" style="max-width:100%">
                    <source src="/videos/Trip${props.CurrentScenario}FrontCamera.mkv" type="video/mp4" autoPlay />
                </video>`

                // TO show that button as active/selected one.
                document.getElementById(e.currentTarget.id).classList += " activeView"

                // TO show remaining buttons as unselected.
                document.getElementById("Rear").classList = "Viewbtn"
                document.getElementById("Left").classList = "Viewbtn"

                // To show that Image/Video and hide others.                
                document.getElementsByClassName('FrontImgVid')[0].style.display = "flex"
                document.getElementsByClassName('RearImgVid')[0].style.display = "none"
                document.getElementsByClassName('SideImgVid')[0].style.display = "none"

                // To reload the useEffect, so to sync this video, as it has loaded again.
                setCurrentVid(currentVid + 1)

                // Handling the video ends event
                document.getElementById('Vid').addEventListener('ended', function (e) {
                    document.getElementsByTagName("video")[0].replaceWith("You've safely reached the destination")
                })
            }
            else if (e.currentTarget.id === "Rear") {
                // setCurrentVid('Trip1RearCamera.mkv')
                document.getElementById(e.currentTarget.id).classList += " activeView"

                document.getElementsByClassName('RearImgVid')[0].style.display = "block"
                document.getElementsByClassName('FrontImgVid')[0].style.display = "none"
                document.getElementsByClassName('SideImgVid')[0].style.display = "none"

                document.getElementById("Front").classList = "Viewbtn"
                document.getElementById("Left").classList = "Viewbtn"
            }
            else {
                // setCurrentVid('Trip1LeftCamera.mkv')
                document.getElementById(e.currentTarget.id).classList += " activeView"

                document.getElementsByClassName('SideImgVid')[0].style.display = "block"
                document.getElementsByClassName('FrontImgVid')[0].style.display = "none"
                document.getElementsByClassName('RearImgVid')[0].style.display = "none"

                document.getElementById("Rear").classList = "Viewbtn"
                document.getElementById("Front").classList = "Viewbtn"
            }
        }
    }


    return (
        <div className='containerCam Responsivity' style={{ height: '39.55vw', zoom:1.04 }}>
            {/* Buttons */}
            <div className='item1'>
                <div className='Viewbtn activeView' title='Front View' id='Front' onClick={(e) => { initializePage(e) }}><Link to='#' style={{
                    textDecoration: 'none', color: 'white'
                }}>Front View</Link></div>
                <div className='Viewbtn' id='Rear' onClick={(e) => { initializePage(e) }}><Link title='Rear View' to='#' style={{
                    textDecoration: 'none', color: 'white'
                }}>Rear View</Link></div>
                <div className='Viewbtn' title='Left View' id='Left' onClick={(e) => { initializePage(e) }}><Link to='#' style={{
                    textDecoration: 'none', color: 'white'
                }}>Left Side View</Link></div>
            </div>
            {/* Images/Videos */}
            <div className='item2'>
                <span className='FrontImgVid' style={{ marginBottom: 30, background: 'white', minWidth: 985, minHeight: 252, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <video autoPlay={true} muted={true} id="Vid" width={'100%'} title="Front Camera Footage">
                        <source src="/videos/Trip1FrontCamera.mkv" type="video/mp4" autoPlay={true} />
                    </video>
                </span>
                <span className='RearImgVid' style={{ display: 'none', width: '100%', zoom: 1.5 }}>
                    <img src="/images/Rear.png" alt="Rear Camera Footage" />
                </span>
                <span className='SideImgVid' style={{ display: 'none', width: '100%', zoom: 1.5 }}>
                    <img src="/images/Left.png" alt="Left Camera Footage" />
                </span>
            </div>
        </div>
    )
}
