import React from 'react'
import './Call911.css'

export default function Call911() {
    document.title = "Call 911"

    function clearScreen() {
        document.getElementById("result").value = "";
    }

    // This function display values
    function display(value) {
        document.getElementById("result").value += value;
    }

    // This function evaluates the expression and returns result
    function calculate() {
        console.log(document.getElementById("result").value.length)
        if (document.getElementById("result").value === "") {
            alert("Type a Valid Number to Call")
        } else if (document.getElementById("result").value.length > 10) {
            alert("Number can't be longer than 10 Digits.")
        } else if (document.getElementById("result").value.length < 3) {
            alert("Number can't be smaller than 3 Digits.")
        }
        else {
            console.log(document.getElementById("result").value)
            alert(`Calling ${document.getElementById("result").value}...`)
        }
    }

    return (
        <div className='Call911'>
            <button type="button" className="btn btn-dark" onClick={() => { window.close() }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                <span> Back to the Home Page</span>
            </button>

            <div style={{position:'absolute', top:'35%', left:'10%'}}>
                <span colSpan="3"> <input className="display-box" type="text" id="result" disabled value="911" /> </span>
            </div>

            <div className="calculator" style={{marginTop:'5%'}}>
                <div style={{ display: 'flex', width: "100%" }}>
                    <span style={{ width: '33%' }}> <input type="button" value="1" onClick={() => { display('1') }} /> </span>
                    <span style={{ width: '33%' }}> <input type="button" value="2" onClick={() => { display('2') }} /> </span>
                    <span style={{ width: '33%' }}> <input type="button" value="3" onClick={() => { display('3') }} /> </span>
                </div>
                <div style={{ display: 'flex', width: "100%" }}>
                    <span style={{ width: '33%' }}> <input type="button" value="4" onClick={() => { display('4') }} /> </span>
                    <span style={{ width: '33%' }}> <input type="button" value="5" onClick={() => { display('5') }} /> </span>
                    <span style={{ width: '33%' }}> <input type="button" value="6" onClick={() => { display('6') }} /> </span>
                </div>
                <div style={{ display: 'flex', width: "100%" }}>
                    <span style={{ width: '33%' }}> <input type="button" value="7" onClick={() => { display('7') }} /> </span>
                    <span style={{ width: '33%' }}> <input type="button" value="8" onClick={() => { display('8') }} /> </span>
                    <span style={{ width: '33%' }}> <input type="button" value="9" onClick={() => { display('9') }} /> </span>
                </div>
                <div style={{ display: 'flex', width: "100%" }}>

                    {/* <!-- calculate() function evaluates the mathematical expression --> */}
                    <span style={{ width: '33%' }}> <input type="button" value="*" onClick={() => { display('*') }} /> </span>
                    <span style={{ width: '33%' }}> <input type="button" value="0" onClick={() => { display('0') }} /> </span>
                    <span style={{ width: '33%' }}> <input type="button" value="#" onClick={() => { display('#') }} /> </span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span>
                        <div type="button" value="Call" className='call' onClick={() => { calculate() }} id="btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                        </div>
                    </span>
                    <span>
                        <div type="button" value="Cancel" style={{ borderRadius: 0, width: '90%', height: '80px !important', marginLeft: 100, backgroundColor: 'rgb(196,196,201)', color: 'black', textAlign: 'right', display: 'flex', textAlign: 'center', justifyContent: 'center', lineHeight: 5 }} onClick={() => { clearScreen() }} id="btn">
                            Clear
                        </div>
                    </span>
                </div>
            </div >

        </div >
    )
}
