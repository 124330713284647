import React from 'react'
import { db } from "../FireBaseConnection/FireBaseConnection"
import { collection, doc, getDocs, updateDoc } from "firebase/firestore"
import { useState } from 'react'

export default function HepticFeedBack() {
    const NotifAdded_CollectionRef = collection(db, "Notifs");

    document.title = "Heptic FeedBack"
    try { document.getElementById("WelcomeModalButton").click() }
    catch (e) { console.log(e) }

    let a2
    setInterval(() => {
        const getNotifAdded = async () => {
            let data = await getDocs(NotifAdded_CollectionRef);

            let a = data.docs[0]._document.data.value;
            a = a.mapValue.fields.Heptic.booleanValue;

            if (a === true) {
                a2 = data.docs[0]._document.data.value;
                a2 = a2.mapValue.fields.NotifHistory.stringValue;
                a2 = (eval(a2)).at(-1)

                // If it is an emergency notification then highlight call emergency button                
                console.log(a2.Criticality, typeof (a2.Criticality))
                if (a2.Criticality === 1) {
                    window.navigator.vibrate([1000])
                }
                else {
                    window.navigator.vibrate([1000, 100, 1000])
                }

                // Updating Firebase by changing value of Show request.
                updateDoc(doc(db, "Notifs", 'Bldcpia0cF0lbMjHG5ji'), { 'Heptic': false })
            }
        };
        getNotifAdded();
    }, 2000)



    return (
        <>
            {/* <!-- Button trigger modal --> */}
            <button type="button" id="WelcomeModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" style={{ display: 'none' }}>Launch demo modal</button>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        <div className="modal-body">
                            <h1> <div style={{ fontSize: 20, marginTop: 20 }}>Heptic Feedback Provider</div> </h1>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgb(20, 110, 13)' }} onClick={() => { localStorage.setItem('start', 'set') }}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 5 }} width="16" height="16" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                    <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                                </svg>
                                Start
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <hr /><h2 style={{ display: 'flex', alignItems: "center", justifyContent: "center", minHeight: 100 }}>For Heptic Feedback</h2><hr />
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", minHeight: 100 }}>
                <h3> At a time, keep this Page Opened in only one Device.</h3>
            </div>
        </>
    )
}
