import './App.css';
import Home from './Home/Home.js';
import CameraView from './CameraView/CameraView.js';
import Map from './Map/Map.js';
import Chat from './Chat/Chat';
import NotificationHistory from './NotificationHistory/NotificationHistory';
import Help from './Help/Help';
import Setting from './Setting/Setting';
import SettingItem from './Setting/SettingItem';
import SettingSubItem from './Setting/SettingSubItem';
import Call911 from './Call911/Call911';
import Controller from './Controller/Controller';
import HepticFeedBack from './HepticFeedBack/HepticFeedBack';
import PageNotFound from './PageNotFound/PageNotFound';
import { collection, getDocs } from "firebase/firestore"
import { db } from "./FireBaseConnection/FireBaseConnection"

import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { useEffect, useState } from 'react';

function App() {
  // Variable used for marking the time when site is loaded. This variable is further used for keeping videos ongoing and in sync. Or else, only when you're on that page, the video would keep playing and as we go and come from any other page of this site onto the page where a video is there, then the video would start playing again from begining. 
  const [siteStartedAt, setsiteStartedAt] = useState(new Date())
  const [CurrentScenario, setCurrentScenario] = useState(null)

  let StartTheVideosAtModalBegin = setInterval(() => {
    if (localStorage.getItem('start') !== null) {
      setsiteStartedAt(new Date())
      clearInterval(StartTheVideosAtModalBegin)
      localStorage.removeItem('start')
    }
  }, 500)

  // To fetch the scenarios as the site get loaded.  
  useEffect(() => {
    const getNotifAdded = async () => {
      let data = await getDocs(collection(db, "Notifs"));
      let CurrScenario = data.docs[0]._document.data.value;
      CurrScenario = CurrScenario.mapValue.fields.CurrentScenario.integerValue;
      setCurrentScenario(CurrScenario)
    }
    getNotifAdded();
  })

  return (
    <Router hashType="slash">
      <Routes>
        <Route exact path='' element={<Home CurrentScenario={CurrentScenario} siteStartedAt={siteStartedAt} />} />
        <Route exact path='/Home' element={<Home CurrentScenario={CurrentScenario} siteStartedAt={siteStartedAt} />} />
        <Route exact path='/CameraView' element={<CameraView siteStartedAt={siteStartedAt} CurrentScenario={CurrentScenario} />} />
        <Route exact path='/Map' element={<Map />} />
        <Route exact path='/Chat' element={<Chat />} />
        <Route exact path='/NotificationHistory' element={<NotificationHistory />} />
        <Route exact path='/Help' element={<Help />} />
        <Route exact path='/Setting' element={<Setting />} />
        <Route exact path='/Setting/Item' element={<SettingItem />} />
        <Route exact path='/Setting/Accessibility/Item' element={<SettingSubItem />} />
        <Route exact path='/Call911' element={<Call911 />} />
        <Route exact path='/Controller' element={<Controller />} />
        <Route exact path='/HepticFeedBack' element={<HepticFeedBack />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
