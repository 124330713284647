import React from 'react'
import Content from './Content.js'

export default function Controller() {  
  return (
    <div>
      <Content />
    </div>
  )
}
