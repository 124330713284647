import React from 'react'
import './MapStyle.css'

export default function Content() {
  return (
    <div style={{ height: '39.55vw', zoom:1.04 }} className='containerMap Responsivity'>
      <div className='item1' style={{ paddingTop: '99.6%', paddingLeft: '6%', border: '1px solid black' }}>
        <img src="/images/Speed Limit.png" alt="Map" style={{ zoom: 1.2 }} />
      </div>

      <div className='item2'>
        <div className='DesinationTime'>
          <div>
            <h5 style={{ display: 'inline-block', minWidth: '40%', color: 'rgb(20,110,129)' }}><b> Destination </b></h5>
            <h4 style={{ display: 'inline', color: 'rgb(2,91,130)', fontWeight: 800 }}>University at Buffalo</h4>
          </div>
          <div>
            <h5 style={{ display: 'inline-block', minWidth: '40%', color: 'rgb(20,110,129)' }}><b> Arrival Time </b></h5>
            <h4 style={{ display: 'inline', color: 'rgb(2,91,130)', fontWeight: 800 }}>2:40 PM</h4>
          </div>
        </div>
        <div className='Directions'>
          <div>
            <h4 style={{ textAlign: 'left', display: 'inline-block', minWidth: '50%' }}>Directions</h4>
            <div style={{ textAlign: 'right', display: 'inline-block', float: 'right' }}>
              <span title='show all' style={{ textDecoration: 'none', color: 'rgb(4,166,236)', cursor:'pointer' }} className="showall"><h5 onClick={()=>{alert("All the Directions are as shown below")}}> show all </h5></span>
            </div>
            <div className='steps' style={{ minHeight: '394px', background: 'white', display: 'grid', gridTemplateColumns: "15% 85%" }}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-right SpacingsForArrows" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up SpacingsForArrows" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-left SpacingsForArrows" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-right SpacingsForArrows" viewBox="0 0 16 16" style={{marginTop:90}}>
                  <path fillRule="evenodd" d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z" />
                </svg>
              </span>
              <span>
                <sub>0.6mi</sub><div className='DrawHorizontalLine'></div>
                <div style={{ marginTop: 26 }}>Slight right at Springville Ave</div>
                <sub style={{ color: 'grey' }}>148ft</sub><div className='DrawHorizontalLine'></div>
                <div style={{ marginTop: 30 }}>Continue straight</div>
                <sub style={{ color: 'grey' }}>400ft</sub><div className='DrawHorizontalLine'></div>
                <div style={{ marginTop: 30 }}>Use the left two lanes to turn left onto US-62 N/Baley</div>
                <sub style={{ color: 'blue' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16" style={{marginRight:5}}>
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                  </svg>
                  Pass by KFC (on the left in 0.3mi)</sub><br />
                <sub style={{ color: 'grey' }}>0.3mi</sub><div className='DrawHorizontalLine'></div>
                <div style={{ marginTop: 30 }}>Use the two right lanes to turn slightly right into US-62 N/Baley</div>
                <sub style={{ color: 'grey' }}>2.1mi</sub><div className='DrawHorizontalLine'></div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='item3'>
        <div className='controls'>
          <h4>Optional</h4>
          <ul style={{ listStyle: 'none', color: 'rgb(125, 125, 125)' }}>
            <li>
              <span><input type="checkbox" title='Speed Limit On/Off' aria-hidden={true}/></span>
              <span style={{ display: 'inline-block', marginTop: 4, marginRight: 50, fontSize: 18 }}> Speed Limit </span>
              <span className='info tool_tip'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
                <span className='tooltip_text'>Shows the speed limit</span>
              </span>
            </li>
            <li style={{ marginTop: 20, marginBottom: 20 }}>
              <span><input type="checkbox" title='Current Speed On/Off' aria-hidden={true}/></span>
              <span style={{ marginRight: 35, fontSize: 18 }}>Current Speed</span>
              <span className='info tool_tip'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
                <span className='tooltip_text'>Shows the Current Speed</span>
              </span>
            </li>
            <li>
              <span><input type="checkbox" title='Show Traffic On/Off'  aria-hidden={true}/></span>
              <span style={{ marginRight: 50, fontSize: 18 }}>Show Traffic</span>
              <span className='info tool_tip'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
                <span className='tooltip_text'>Shows the Traffic</span>
              </span>
            </li>
          </ul>
        </div>

        <div className='help'>
          <h4> Need help? </h4>

          <div title="Change Destination" className="alert" style={{ backgroundColor: 'white', border: '2px solid rgba(32, 110, 144)', padding: '10px', borderRadius: '12px' }} onClick={()=>{alert("Your Request has been received.")}}>
            <span style={{ paddingTop: '15%', marginLeft: '5px', minWidth: 55, minHeight: 55, borderRadius: 27.5, border: '2px solid #F06449', backgroundColor: 'rgb(247,247,249)', textAlign: 'center', verticalAlign: 'middle' }}>
              <img src="/images/Polyline.png" alt="Change Destination" style={{ zoom: 0.5 }} aria-hidden={true}/>
            </span>
            <h5 style={{ fontSize: 19, color: '#F06449', textAlign: 'center', verticalAlign: 'middle', fontWeight: 600, lineHeight: '46px', paddingTop: '2px' }}>
              Change Destination
            </h5>
          </div>

          <div title="Vehicle Monitor" className="alert" style={{ backgroundColor: 'white', border: '2px solid rgba(32, 110, 144)', padding: '10px', borderRadius: '12px' }} onClick={()=>{alert("Your Request has been received.")}}>
            <span style={{ paddingTop: '18%', marginLeft: '5px', minWidth: 55, minHeight: 55, borderRadius: 27.5, border: '2px solid #F06449', backgroundColor: 'rgb(247,247,249)', textAlign: 'center', verticalAlign: 'middle' }}>
              <img src="/images/clip.png" alt="notificationIcon" aria-hidden={true} />
            </span>
            <h5 style={{ fontSize: 19, color: '#F06449', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', lineHeight: '45px', paddingTop: '2px' }}>
              Vehicle Monitor
            </h5>
          </div>

          <div title="Change Laguage" className="alert" style={{ backgroundColor: 'white', border: '2px solid rgba(32, 110, 144)', padding: '10px', borderRadius: '12px' }} onClick={()=>{alert("Your Request has been received.")}}>
            <span style={{ paddingTop: '17%', marginLeft: '5px', minWidth: 55, minHeight: 55, borderRadius: 27.5, border: '2px solid #F06449', backgroundColor: 'rgb(247,247,249)', textAlign: 'center', verticalAlign: 'middle' }}>
              <img src="/images/clarity_language-line.png" alt="notificationIcon" style={{ zoom: 1.12 }} aria-hidden={true} />
            </span>
            <h5 style={{ fontSize: 19, color: '#F06449', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', lineHeight: '48px' }}>
              Change Language
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}
