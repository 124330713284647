import React from 'react'
import './HomeStyle.css'
import { Link } from 'react-router-dom'

export default function Header(props) {
    setTimeout(() => {
        document.getElementById('MapIcon').style.background = 'none'
        document.getElementById('MapIcon').style.outline = 'none'
        document.getElementsByClassName('MapVid')[0].style.border = 'none'
        document.getElementsByClassName('MapVidHeader')[0].style.border = 'none'
    })
    return (        
        <div className='item2'>
            <div className='TopBar'>
                <div className='QuickAccess'>Quick Access</div>
                <div className='SeeMore' style={{textDecoration: 'none', color: 'rgb(255, 64, 0)', cursor:'pointer'}} onClick={()=>{alert("All the Options are as shown below.")}}>See more </div>
            </div>
            <div className='Cards'>
                <Link to="#" className='Card' title="" style={{ textDecoration: 'none', color: '#F06449', marginTop: '0px' }} aria-label="view route" onClick={()=>{alert("Your Request has been received.")}}>
                    <div className='CardIcon'><img src="/images/Card1.png" alt="" title='' style={{zoom:0.8}}/></div>
                    <div className='CardText' style={{paddingTop:4, lineHeight:1.05, display:'flex', justifyContent:'center'}} aria-hidden={true}>View Route</div>
                </Link>
                <Link to="#" className='Card' title="Change Destination" style={{ textDecoration: 'none', color: '#F06449', marginTop: '0px' }} onClick={()=>{
                    let EnteredDest = prompt("Enter the Destination for your next trip:"); 
                    if(EnteredDest.length===0){
                        alert("No Desination entered")
                    }else{
                        alert(`Destination set to: ${EnteredDest}. Trip Time will be increased by 15 minutes.`);
                    }
                  }} aria-label="Change Destination">
                    <div className='CardIcon'><img src="/images/Card2.png" alt="" title='Change Destination' style={{zoom:0.95}}/></div>
                    <div className='CardText' style={{paddingTop:4, lineHeight:1.05, display:'flex', justifyContent:'center'}} aria-hidden={true}>Change Destination</div>
                </Link>
                <Link to="#" className='Card' title="Payment Options" style={{ textDecoration: 'none', color: '#F06449', marginTop: '0px' }} onClick={()=>{alert("Your Request has been received.")}} aria-label="Payment Options">
                    <div className='CardIcon'><img src="/images/Card3.png" alt="" title='Payment Options' style={{zoom:0.8}}/></div>
                    <div className='CardText' style={{paddingTop:4, lineHeight:1.05, display:'flex', justifyContent:'center'}} aria-hidden={true}>Payment Options</div>
                </Link>
                <Link to="#" className='Card' title="Display Settings" style={{ textDecoration: 'none', color: '#F06449', marginTop: '0px' }} onClick={()=>{alert("Your Request has been received.")}} aria-label="Display Settings">
                    <div className='CardIcon'><img src="/images/Card4.png" alt="" title='Display Settings' style={{zoom:0.8}}/></div>
                    <div className='CardText' style={{paddingTop:4, lineHeight:1.05, display:'flex', justifyContent:'center'}} aria-hidden={true}>Display Settings</div>
                </Link>
                <Link to="#" className='Card' title="Audio Settings" style={{ textDecoration: 'none', color: '#F06449', marginTop: '0px' }} onClick={()=>{alert("Your Request has been received.")}} aria-label="Audio Settings">
                    <div className='CardIcon'><img src="/images/Card5.png" alt="" title='Audio Settings' style={{zoom:0.8}}/></div>
                    <div className='CardText' style={{paddingTop:4, lineHeight:1.05, display:'flex', justifyContent:'center'}} aria-hidden={true}>Audio Settings</div>
                </Link>
                <Link to="#Tutorial" className='Card' title="Tutorial Mode" style={{ textDecoration: 'none', color: '#F06449', marginTop: '0px' }} onClick={() => { props.data("Tutorial") }} aria-label="Tutorial Mode">
                    <div className='CardIcon'><img src="/images/Card6.png" alt="" title='Tutorial Mode' style={{zoom:0.8}}/></div>
                    <div className='CardText' style={{paddingTop:4, lineHeight:1.05, display:'flex', justifyContent:'center'}} aria-hidden={true}>Tutorial Mode</div>
                </Link>
            </div>
        </div>
    )
}