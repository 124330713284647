import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { db } from "../FireBaseConnection/FireBaseConnection"
import { collection, doc, getDocs, updateDoc } from "firebase/firestore"
import './HeaderStyle.css'

export default function Header() {
    // Fetching Data from Firebase
    const NotifAdded_CollectionRef = collection(db, "Notifs");
    let timeout1 = 10000000
    let timeout2 = 10000000

    // For when a new Page is loaded, if from previous page the notification was still announcing, that would be stopped.
    window.speechSynthesis.cancel()

    // For Vocally Announcing the Notification. 
    function Vocalizer() {
        const msg = new SpeechSynthesisUtterance()
        if (msg.text !== undefined && localStorage.getItem("a2Title")) {
            if (localStorage.getItem("a2Criticality") && localStorage.getItem("a2Criticality") === '2') {
                // adding heptic feedback
                window.navigator.vibrate([1000, 100, 1000])
                // Adding a an alarm sound prefacing the title announcement
                let HighCriticalityAudio = new Audio('audio/HighCriticality.flac')
                HighCriticalityAudio.play()
            } else {
                window.navigator.vibrate([1000])
                let LowCriticalityAudio = new Audio('audio/LowCriticality.wav')
                LowCriticalityAudio.play()
            }
            msg.text = JSON.stringify(localStorage.getItem("a2Title"))
            window.speechSynthesis.pause()
            setTimeout(() => {
                window.speechSynthesis.resume()
            }, 1200)
            window.speechSynthesis.speak(msg)
        }
    }


    // Keeps checking from Firebase, if any new Show request has been made. If so, make required updates.
    let a2
    setInterval(() => {
        const getNotifAdded = async () => {
            let data = await getDocs(NotifAdded_CollectionRef);

            let a = data.docs[0]._document.data.value;
            a = a.mapValue.fields.NotifAdded.booleanValue;

            if (a === true) {
                a2 = data.docs[0]._document.data.value;
                a2 = a2.mapValue.fields.NotifHistory.stringValue;
                a2 = (eval(a2)).at(-1)

                localStorage.setItem("a2Title", a2.Title)
                localStorage.setItem("a2Desc", a2.Desc)
                localStorage.setItem("a2Pointers", a2.Pointer)
                localStorage.setItem("a2Criticality", a2.Criticality)
                // console.log('a2-', a2)

                // After fetching the value from Firebase, it stops the previously being announced notif, opens i.e. unhides that notification on the user's site and announces this new notification.
                window.speechSynthesis.cancel()
                openNotif()
                Vocalizer()

                // Automatically close Notifs after 13 seconds, if showMore has not pressed. If showMore has been pressed then it'll get 10 more seconds before closing the Notif, if it less critical Notif. For More Critical Notif it will stay open only, after the showmore is pressed as a selection has to be made. Only an exception for highPrior Notif is for "Vehicle self-checking" where no choice has to be made.
                console.log("Before setting timeouts", timeout1, timeout2)
                timeout1 = setTimeout(() => {
                    console.log("Got in")
                    if (localStorage.getItem("ShowMore") === "true") {
                        if (a2.Criticality === 1) {
                            timeout2 = setTimeout(() => {
                                if (a2.Title != "Denser fog may impact travel" && a2.Title != "The emergency button has been pressed. The vehicle will pull over as soon as it is safe to do so. Please remain seated."&& a2.Title != "Bumper-to-bumper collision") {
                                    closeNotif()
                                }
                            }, 10000)
                        }
                        else if (a2.Title === "Vehicle status check") {
                            timeout2 = setTimeout(() => {
                                if (a2.Title != "Denser fog may impact travel" && a2.Title != "The emergency button has been pressed. The vehicle will pull over as soon as it is safe to do so. Please remain seated." && a2.Title != "Bumper-to-bumper collision") {
                                    closeNotif()
                                }
                            }, 7500)
                        }
                    }
                    else {
                        if (a2.Title != "Denser fog may impact travel" && a2.Title != "The emergency button has been pressed. The vehicle will pull over as soon as it is safe to do so. Please remain seated." && a2.Title != "Bumper-to-bumper collision") {
                            closeNotif()
                        }
                    }
                }, 14000)
                console.log("Timeout set", timeout1, timeout2)

                // If it is an emergency notification then highlight call emergency button                
                if (a2.Title === "The emergency button has been pressed. The vehicle will pull over as soon as it is safe to do so. Please remain seated.") {
                    document.getElementsByClassName('navlink1')[0].style.background = 'rgb(245,171,61)'
                    setTimeout(() => {
                        document.getElementsByClassName('navlink1')[0].style.background = "#45473A";
                    }, 10500)
                }

                // Updating Firebase by changing value of Show request.
                updateDoc(doc(db, "Notifs", 'Bldcpia0cF0lbMjHG5ji'), { 'NotifAdded': false })
            }
        };
        getNotifAdded();
    }, 4000)


    // To make the whole Header Icon (Card) clickable and not just the image in the Icon. The li tags are made capable of redirecting like Link tags by this function.
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate(path);
    }


    // Helper Function. Closes the Notification on User's site.
    function closeNotif() {
        if (localStorage.getItem("ShowMore") === "true") {
            localStorage.removeItem("ShowMore")
        }
        try {
            clearTimeout(timeout1)
            timeout1 = null
            clearTimeout(timeout2)
            timeout2 = null
            console.log("Time Out Cleared", timeout1, timeout2)
        }
        catch (e) {
            console.log(e)
        }

        document.getElementById('NotificationDIV').classList.remove('NotifShow')
        document.getElementById('NotificationDIV').classList += ' NotifHide'

        // Stops the announcement as soon as close is clicked
        window.speechSynthesis.cancel()
    }

    // Helper function. Responsible for displaying the Notification on User's site.
    function openNotif() {
        try {
            console.log("timeout1", timeout1)
            clearTimeout(timeout1)
            timeout1 = null
            clearTimeout(timeout2)
            timeout2 = null
        }
        catch (e) {
            console.log(e)
        }

        try {
            document.getElementById('NotificationDIV').classList.remove('NotifHide')
            document.getElementById('NotificationDIV').classList += ' NotifShow'
        }
        catch (e) { }
        document.getElementsByClassName('NotifShowMore')[0].classList.remove('NotifHide');
        document.getElementsByClassName('DescContainer')[0].classList += ' NotifHide';
        document.getElementsByClassName('PointersContainer')[0].classList += ' NotifHide';

        document.getElementsByClassName('NotificationTitle')[0].innerHTML = localStorage.getItem("a2Title")
        document.getElementsByClassName('DescContainer')[0].innerHTML = localStorage.getItem("a2Desc")
        document.getElementsByClassName('PointersContainer')[0].innerHTML = localStorage.getItem("a2Pointers")
    }


    // Showing the Description as User Clicks on "Show more..." in the Notification.
    function ShowMoreNotifInfo() {
        // If show more is clicked then user should get more time to read it before the notification disappears:
        localStorage.setItem("ShowMore", true)

        document.getElementsByClassName('NotifShowMore')[0].classList += ' NotifHide';
        document.getElementsByClassName('DescContainer')[0].classList.remove('NotifHide');
        document.getElementsByClassName('PointersContainer')[0].classList.remove('NotifHide');

        // Announces the Desciption
        let DescAnnouncement = new SpeechSynthesisUtterance()
        DescAnnouncement.text = JSON.stringify(localStorage.getItem("a2Desc"))
        DescAnnouncement.text = DescAnnouncement.text.replaceAll("\\n", " ")
        window.speechSynthesis.speak(DescAnnouncement)

        // Announces the Pointer in the Description
        if (localStorage.getItem("a2Pointers")) {
            let PointerAnnouncement = new SpeechSynthesisUtterance()
            PointerAnnouncement.text = JSON.stringify(localStorage.getItem("a2Pointers")).replace(/<\/?[^>]+(>|$)/g, "");
            window.speechSynthesis.speak(PointerAnnouncement)
        }

        // Responsible for Loading Pointers in the notifications where bulletins are to be shown as loading initially, and then Done.        
        if (localStorage.getItem("a2Criticality") === "2") {
            // Displaying Notification with Loading GIFs            
            if (localStorage.getItem("a2Title") === "Vehicle status check") {
                DisplayLoadingPointer(Array.from(document.getElementsByClassName('PointersContainer'))[0])
            }
        }

        // Making All Pointers in displayed notification, as Clickable.
        if (localStorage.getItem("a2Criticality") === '2' && localStorage.getItem("a2Criticality") !== '1') {
            FollowupModal()
        }
    }

    // Helper function. For opening Followup Notifications to main notifications.
    function FollowupModal() {
        Array.from(document.getElementsByClassName('ClickablePointers')).forEach((EachPointerItem) => {
            EachPointerItem.addEventListener("click", () => {
                EachPointerItem.classList.remove("ClickablePointers")

                let FollowupNotif_Message
                console.log(EachPointerItem.className.toLowerCase(), EachPointerItem.className.toLowerCase() === "exit and travel to your final destination on own.")
                if (EachPointerItem.className.toLowerCase() === "wait for emergency services to arrive.") {
                    FollowupNotif_Message = 'Okay. Emergency services will arrive here in 6 minutes.'
                } else if (EachPointerItem.className.toLowerCase() === "wait for the emergency services to arrive.") {
                    FollowupNotif_Message = 'Okay. Emergency services will arrive here in 6 minutes.'
                } else if (EachPointerItem.className.toLowerCase() === "request a new vehicle.") {
                    FollowupNotif_Message = 'Okay. A request has been sent for a new vehicle.'
                } else if (EachPointerItem.className.toLowerCase() === "exit and travel to your final destination on own.") {
                    FollowupNotif_Message = 'Okay. Please proceed safely to your final destination. The door will open soon.'
                } else if (EachPointerItem.className.toLowerCase() === "exit now regardless of the obstacles identified.") {
                    FollowupNotif_Message = 'Okay. The door will open soon. Please proceed carefully.'
                } else if (EachPointerItem.className.toLowerCase() === "request another nearby drop-off location.") {
                    FollowupNotif_Message = 'Okay. We will change the drop-off location to around the corner in 300 feet ahead.'
                } else if (EachPointerItem.className.toLowerCase() === "wait for assistance to remove the obstacles.") {
                    FollowupNotif_Message = 'Okay. It may take some time for us to find help. Do you wish to contact someone at this location to help you?'
                } else {
                    FollowupNotif_Message = "Okay. Your request has been considered."
                }

                // Announcing Text of Followup Notification
                window.speechSynthesis.cancel()
                const FollowuoNotif_Message_Announce = new SpeechSynthesisUtterance()
                FollowuoNotif_Message_Announce.text = JSON.stringify(FollowupNotif_Message)
                window.speechSynthesis.speak(FollowuoNotif_Message_Announce)

                document.getElementsByClassName('NotificationTitle')[0].innerHTML = FollowupNotif_Message
                document.getElementsByClassName('DescContainer')[0].classList += ' NotifHide'
                document.getElementsByClassName('PointersContainer')[0].classList += ' NotifHide'

                // To automatically clear the followup notification
                timeout2 = setTimeout(() => {
                    closeNotif()
                }, 10500)
            })
        })
    }

    // Helper functions. Responsible for Loading Pointers in the notifications where bulletins are to be shown as loading initially, and then Done.
    function DisplayLoadingPointer(thePointerHolderElem) {
        thePointerHolderElem.innerHTML = `
                <ul id="Loaderul">
                    <li class='MonitoredItem'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>                                
                        <span class='MonitoredItemDone closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Taillights</span>
                    </li>
                    <li class='MonitoredItem closed'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>
                        <span class='MonitoredItemDone closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Rear-Ultrasonic sensors</span>
                    </li>
                    <li class='MonitoredItem closed'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>
                        <span class='MonitoredItemDone closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Rear-Radar Sensors</span>
                    </li>
                    <li class='MonitoredItem closed'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>
                        <span class='MonitoredItemDone closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Crash Sensors</span>
                    </li>
                    <li class='MonitoredItem closed'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>
                        <span class='MonitoredItemDone closed closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Lidar Sensors</span>
                    </li>
                    <li class='MonitoredItem closed'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>
                        <span class='MonitoredItemDone closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Front-Ultrasonic Sensors</span>
                    </li>
                    <li class='MonitoredItem closed'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>
                        <span class='MonitoredItemDone closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Front-Radar Sensors</span>
                    </li>
                    <li class='MonitoredItem closed'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>
                        <span class='MonitoredItemDone closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Battery</span>
                    </li>
                    <li class='MonitoredItem closed'>
                        <span class="MonitoredItemLoading spinner-border text-warning" role="status"></span>
                        <span class='MonitoredItemDone closed'> <img src="images/InspectionGreenTick.png" alt="GreenTickIcon" title="Done." /> </span>
                        <span class='MonitoredItemText'>Braking system</span>
                    </li>
                </ul>
            `

        let count = 1
        let showMonitoredItems = setInterval(() => {
            // try catch is used here as below statements of the setInterval kept on executing even if move to a different page. Hence, in case of statements in try block, when new page gets opened up while this setInterval was not cleared, then html of new page would not have any element with className MonitoredItem and that kept throwing errors.
            try {
                document.getElementsByClassName('MonitoredItem')[count].classList.remove("closed")
            }
            catch (error) {
                clearInterval(showMonitoredItems);
            }

            if (count === document.getElementsByClassName('MonitoredItem').length - 1) {
                clearInterval(showMonitoredItems);
                setTimeout(ChangeLoadingIconAsDone, 2000)
            }
            count += 1
        }, 750)
    }

    // Helper function to Change Loading GIF to Done Image.
    function ChangeLoadingIconAsDone() {
        let count2 = 0
        Array.from(document.getElementsByClassName('MonitoredItemLoading')).forEach((elem) => {
            elem.remove()
            document.getElementsByClassName('MonitoredItemDone')[count2].classList = "MonitoredItemDoneOk"
        })
        const Donemsg = new SpeechSynthesisUtterance()
        Donemsg.text = JSON.stringify("Vehicle Systems Okay.")
        window.speechSynthesis.speak(Donemsg)
    }


    // to unset session storage so each time further we could detect when page first loads, which helps us to show welcome modal.
    window.addEventListener("beforeunload", () => {
        sessionStorage.clear()
        // localStorage.clear()
    })


    // To set session Storage and show Welcome Modal on first time loading the site.
    useEffect(() => {
        // To detect if page has loaded for first time. Because, if it has loaded for first time, we'll need a user click, so that the audio functions could start working. We'll need audio for background sounds and for announcing notifications. We'll incorporate the task of getting a click by using a welcome modal.
        if (sessionStorage.getItem('PageHasBeenLoaded') == null) {
            sessionStorage.setItem('PageHasBeenLoaded', "Mulitple times")
            document.getElementById("WelcomeModalButton").click()

            // To set date & time in modal
            let currentMonthYear = new Date().toLocaleString('en-us', { month: 'short', year: 'numeric' })
            let currentDate = new Date().getDate()

            const d = new Date();
            let hrs = d.getHours();
            let m = d.getMinutes();
            // Condition to add zero before minute
            let min = m < 10 ? `0${m}` : m;
            const currTime = hrs >= 12 ? `${hrs - 12}:${min} pm` : `${hrs}:${min} am`;

            document.getElementsByClassName('modal-time')[0].innerHTML = `${currTime} `
            document.getElementsByClassName('modal-date')[0].innerHTML = `${currentDate} ${currentMonthYear}`
        }
    })

    function CallEmergencyDropDown() {
        // Toggle b/n show and hide dropdown when Emergency button is pressed
        let selectedElem = document.getElementsByClassName("CallEmergencyDropDown")[0]

        if (selectedElem.classList.contains("hide")) {
            document.getElementsByClassName("CallEmergencyDropDown")[0].classList.remove("hide")
        }
        else {
            selectedElem.classList += " hide"
        }
    }

    // Close dropdown when clicked anywhere outside of Emergency button or dropdown itself.
    window.onclick = function (event) {
        // console.log(event.clientX, event.clientY, 38 > event.clientX > 270, 66 > event.clientY > 206)
        if (event.clientX > 270 || event.clientY > 206) {
            if (document.getElementsByClassName("CallEmergencyDropDown")[0].classList.contains("hide") === false) {
                document.getElementsByClassName("CallEmergencyDropDown")[0].classList += " hide"
            }
        }
    }

    return (
        <nav className="navbar headerContainer navbar-expand-lg bg-dark">
            {/* <!-- Button trigger modal --> */}
            <button type="button" id="WelcomeModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" style={{ display: 'none' }}>Launch demo modal</button>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        <div className="modal-header">
                            <h5 className="modal-title center" id="exampleModalLongTitle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-fill" viewBox="0 0 16 16" style={{ marginRight: 5 }}>
                                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                </svg>
                                Cloudy and 56<b>&#x2109;</b>
                            </h5>
                        </div>
                        <div className="modal-body">
                            <b><span className='modal-date' style={{ fontSize: 15, marginRight: 15 }}></span></b>
                            <span className='modal-time' style={{ fontSize: 30 }}></span>
                            <div style={{ fontSize: 15, marginTop: 20 }}>Destination: University At Buffalo</div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgb(240, 100, 73)' }} onClick={() => { localStorage.setItem('start', 'set') }}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 5 }} width="16" height="16" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                    <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                                </svg>
                                Begin
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* Notification */}
            <div id="NotificationDIV" className="NotifHide alert alert-success alert-dismissible fade show" role="alert">
                <div className='NotifBellImage'>
                    <img src="/images/Notif.png" alt="Notification" />
                </div>
                <span style={{ maxWidth: '90%', display: 'inline-block' }}>
                    <button title="close notification" type="button" className="btn-close" onClick={() => { closeNotif() }}></button>
                    <h4 className="NotificationTitle"></h4>
                    <p className='NotifShowMore' onClick={() => { ShowMoreNotifInfo() }}>
                        <button type="button" className="btn btn-warning" style={{ zoom: 0.7, margin: '0px 8px', background: 'rgb(240, 100, 73)', color: 'white' }}> Show more... </button>
                    </p>
                    <p className="DescContainer NotifHide"></p>
                    <p className='PointersContainer NotifHide'></p>
                </span>
            </div>

            {/* Header NavBar */}
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item headerLink1 ToggleDropDown" onClick={() => { CallEmergencyDropDown() }}>
                            <Link title='Call Emergency ToggleDropDown' className="nav-link navlink1" to="#">
                                <img src="/images/header/Siren.png" className='ToggleDropDown' alt="" width={'31x'} />
                            </Link>
                            <div className='CallEmergencyDropDown hide'>
                                <div onClick={() => { window.open("#/Call911") }}> Call 911</div>
                                <div onClick={() => { alert("Your request is being processed, and the vehicle will be pulled over") }}>Pull over the vehicle</div>
                            </div>
                        </li>
                        <li className="nav-item headerLink2" onClick={() => { routeChange('/NotificationHistory') }}>
                            <Link title='Notifications' className="nav-link navlink2" to="/NotificationHistory">
                                <img src="/images/header/NotificationBell.png" alt="" width={'26px'} />
                            </Link>
                        </li>
                        <li className="nav-item headerLink2" onClick={() => { routeChange('/Help') }}>
                            <Link title='Help' className="nav-link navlink3" to="/Help" style={{}}>
                                <img src="/images/header/Help.png" alt="" width={'15px'} />
                            </Link>
                        </li>
                        <li className="nav-item headerLink2" onClick={() => { routeChange('/Setting') }}>
                            <Link title="Settings" className="nav-link navlink4" to="/Setting">
                                <img src="/images/header/Settings.png" alt="" width={'32.3px'} />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav >
    )
}
